import {ROOT_PATH} from '@/components/pages/oberlo/constants/index';
import type {
  ShopifyOberloData,
  OberloTopic,
} from '@/components/pages/oberlo/types';

import {TAG_KEY, storiesData} from '../constants/Stories';

import type {ArticleLinksProps} from './GetArticleData';
import {getArticleLinks} from './GetArticleData';

type StoriesDataProps = {
  dataArticles: ShopifyOberloData[];
  topics: OberloTopic[];
  lang: string;
};

export const getStoriesData = ({
  dataArticles,
  topics,
  lang,
}: StoriesDataProps) =>
  dataArticles.map((story, index) => {
    const articleStyles = storiesData[index].articleClass;

    const storyLinks = getArticleLinks({
      topics,
      lang,
      articleData: story,
    }) as ArticleLinksProps;

    const key = story.tags
      .filter((tag: string) => tag.includes(TAG_KEY))[0]
      .replace(TAG_KEY, '');

    return {
      id: story.id,
      title: story.title,
      titleLink: `${lang}${ROOT_PATH}/${key}/${story.handle}`,
      storyLink: storyLinks?.articleLink || '',
      storyTitle: storyLinks?.articleTitle,
      articleStyles,
      src: story.image.url,
      alt: story.image.altText,
    };
  });
