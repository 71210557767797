import type {ImageProps} from '@/components/pages/oberlo/components/Image/Image';
import Image from '@/components/pages/oberlo/components/Image/Image';
import OberloTypography from '@/components/pages/oberlo/components/OberloTypography/Typography';
import Button from '@/components/pages/oberlo/components/LinkButton/LinkButton';
import {twMerge} from '@/stylesheets/twMerge';
import {linkCardGradient} from '@/components/pages/oberlo/styles/styles';

type StoryItemProps = {
  articleStyles: string;
  src?: string;
  height?: number;
  width?: number;
  sourses?: ImageProps[];
  titleLink: string;
  title: string;
  storyLink?: string;
  storyTitle?: string;
};

export default function StoryItem({
  articleStyles,
  src,
  titleLink,
  title,
  storyLink,
  storyTitle,
  ...acticleData
}: StoryItemProps) {
  return (
    <article className={`col-span-4 ${articleStyles}`}>
      {src && (
        <Image
          {...acticleData}
          src={src}
          className="absolute w-full h-full object-cover"
          loading="lazy"
          pictureStyles="bg-cover link-card-image h-0 pb-9/16"
        />
      )}
      <div
        className={
          src
            ? 'absolute top-0 w-full h-full flex flex-col justify-end z-20 p-8'
            : 'h-full'
        }
      >
        <Button
          type="link"
          className={
            src
              ? twMerge(
                  linkCardGradient,
                  'text-gray-10 hover:underline focus:underline mt-2',
                )
              : 'flex items-center px-6 py-12 h-full bg-black hover:bg-blue-50 focus:bg-blue-50 transition-colors ease-in duration-200'
          }
          link={titleLink}
        >
          <OberloTypography
            as="h3"
            className={`text-gray-10 line-clamp-3 z-30 relative hover:underline ${
              src
                ? 'text-heading-base font-sans'
                : 'text-display-base font-display uppercase text-center break-words max-w-full'
            }`}
          >
            {title}
          </OberloTypography>
        </Button>
        {storyLink && (
          <Button
            type="link"
            className="order-first self-start text-label font-sans uppercase lg:text-size-xs text-sky-30 hover:underline relative z-30"
            link={storyLink}
          >
            <OberloTypography as="span">{storyTitle}</OberloTypography>
          </Button>
        )}
      </div>
    </article>
  );
}
