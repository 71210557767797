import type {LinksFunction} from '@remix-run/server-runtime';
import {useLoaderData} from '@remix-run/react';

import type {Handle} from '@/types';
import {Theme} from '@/enums';
import customStyleSheet from '@/stylesheets/oberlo/oberlo.css?url';
import OberloHero from '@/components/pages/oberlo/Home/OberloHero/OberloHero';
import Podcast from '@/components/pages/oberlo/Home/Podcast/Podcast';
import YourLife from '@/components/pages/oberlo/Home/YourLife/YourLife';
import StartYourBusiness from '@/components/pages/oberlo/Home/StartYourBusiness/StartYourBusiness';
import OurPicks from '@/components/pages/oberlo/Home/OurPicks/OurPicks';
import Stories from '@/components/pages/oberlo/Home/Stories/Stories';
import OberloPageLayout from '@/components/pages/oberlo/PageLayout/PageLayout';
import WebPageSchema from '@/components/pages/oberlo/components/WebPageSchema/WebPageSchema';

export const handle: Handle = {
  theme: Theme.Oberlo,
};

const links: LinksFunction = () => {
  return [{rel: 'preload stylesheet', as: 'css', href: customStyleSheet}];
};

export {oberloLoader as loader} from '@/pages/oberlo.com/($locale)/server/loaders/loader.server';

export default function Oberlo() {
  const data = useLoaderData<any>() ?? {};
  const startYourBusinessData = data?.processedData?.ourPicks?.slice(2, 4);
  const yourLifeData = data?.processedData?.yourLife?.slice(0, 4);
  const isEnglishLocale = data?.site?.locale === 'en';

  return (
    <OberloPageLayout links={links}>
      <div className="min-h-screen flex flex-col">
        <WebPageSchema />
        <OberloHero referringPageUrl={data?.canonicalUrl || data?.url || ''} />
        <OurPicks
          ourPicksData={data?.processedData?.ourPicks}
          topics={data?.processedData?.topics}
        />
        <Stories
          storiesData={data?.processedData?.stories}
          topics={data?.processedData?.topics}
        />
        {isEnglishLocale && (
          <Podcast podcast={data?.processedData?.podcasts[0]} />
        )}
        <YourLife
          yourLifeData={yourLifeData}
          topics={data?.processedData?.topics}
        />
        <StartYourBusiness
          startYourBusinessData={startYourBusinessData}
          topics={data?.processedData?.topics}
        />
      </div>
    </OberloPageLayout>
  );
}
