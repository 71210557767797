const IMAGE_WIDTH_1 = 322;
const IMAGE_HEIGHT_1 = 322;

const IMAGE_WIDTH_2 = 260;
const IMAGE_HEIGHT_2 = 414;

const IMAGE_WIDTH_3 = 260;
const IMAGE_HEIGHT_3 = 458;

const IMAGE_WIDTH_4 = 300;
const IMAGE_HEIGHT_4 = 462;

const IMAGE_WIDTH_5 = 300;
const IMAGE_HEIGHT_5 = 492;

const DIVIDER_IMAGE_WIDTH = 160;
const DIVIDER_IMAGE_HEIGHT = 24;

export const BUSINESS_IMAGE_LIST_DATA = [
  {width: IMAGE_WIDTH_1, height: IMAGE_HEIGHT_1},
  {width: IMAGE_WIDTH_2, height: IMAGE_HEIGHT_2},
  {width: IMAGE_WIDTH_3, height: IMAGE_HEIGHT_3},
];

export const BUSINESS_IMAGE_LIST_DATA_2 = [
  {width: IMAGE_WIDTH_4, height: IMAGE_HEIGHT_4},
  {width: IMAGE_WIDTH_5, height: IMAGE_HEIGHT_5},
];

export const DIVIDER_IMAGE_SIZES = {
  height: DIVIDER_IMAGE_HEIGHT,
  width: DIVIDER_IMAGE_WIDTH,
};

export const businessImageContainerStyles = [
  'bg-cover max-w-full md:max-w-xl absolute w-40 top-0 -mt-32 pt-4 md:-mt-40',
  'bg-cover w-32 mb-4',
  'bg-cover w-32',
];

export const updatedBusinessImageContainerStyles = [
  'bg-cover w-32 mb-4',
  'bg-cover w-32',
];
