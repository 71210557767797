import {useMemo} from 'react';

import Section from '@/components/pages/oberlo/components/Section/Section';
import type {Loading} from '@/components/pages/oberlo/components/Image/Image';
import Image from '@/components/pages/oberlo/components/Image/Image';
import OberloTypography from '@/components/pages/oberlo/components/OberloTypography/Typography';
import {useTranslations} from '@/hooks/useTranslations';
import {getSourseImages} from '@/components/pages/oberlo/utils/GetImageData';
import {useSiteData} from '@/hooks/useSiteData';
import {ROOT_PATH} from '@/components/pages/oberlo/constants/index';
import type {
  ShopifyOberloData,
  OberloTopic,
} from '@/components/pages/oberlo/types';

import {ARROW_IMAGE_SIZES} from '../constants/Stories';
import {getStoriesData} from '../utils/GetStoriesData';

import StoriesHeader from './StoriesHeader';
import StoryItem from './StoryItem';

type StoriesProps = {
  storiesData: ShopifyOberloData[];
  topics: OberloTopic[];
};

export default function Stories({storiesData, topics}: StoriesProps) {
  const {t, images} = useTranslations();
  const {site} = useSiteData();
  const lang = site?.locale !== 'en' ? `/${site?.pathPrefix}` : '';

  const arrowImage = images.stories.image.en.arrow;

  const arrowImageData = useMemo(
    () => ({
      src: arrowImage.src,
      ...ARROW_IMAGE_SIZES,
      alt: arrowImage.alt,
      loading: 'lazy' as Loading,
      sources: getSourseImages({
        srcSet: arrowImage.srcSet,
        srcSetWebp: arrowImage.srcSetWebp,
      }),
    }),
    [arrowImage],
  );

  const stories = getStoriesData({dataArticles: storiesData, lang, topics});

  return (
    <Section className="grid grid-cols-4 gap-2 pt-12 pb-24 sm:grid-cols-8 lg:container lg:w-full lg:mx-auto xl:grid-cols-12">
      <StoriesHeader />
      {stories.map((story) => (
        <StoryItem key={story.id} {...story} />
      ))}
      <a
        href={`${lang}${ROOT_PATH}${t('pages.home.stories.seeMoreCTALink')}`}
        className="col-span-4 bg-sky-30 text-heading-base font-sans px-8 py-12 flex flex-col justify-end hover:underline hover:bg-violet-10 focus:underline focus:bg-violet-10 transition-colors ease-in duration-200"
      >
        <Image {...arrowImageData} pictureStyles="bg-cover w-40" />
        <OberloTypography as="span">
          {t('pages.home.stories.seeMoreCTA')}
        </OberloTypography>
      </a>
    </Section>
  );
}
