import {useMemo, Fragment} from 'react';

import Section from '@/components/pages/oberlo/components/Section/Section';
import type {ArticleProps} from '@/components/pages/oberlo/components/Article/Article';
import Article from '@/components/pages/oberlo/components/Article/Article';
import type {Loading} from '@/components/pages/oberlo/components/Image/Image';
import Image from '@/components/pages/oberlo/components/Image/Image';
import {useTranslations} from '@/hooks/useTranslations';
import {
  getBusinessImages,
  getSourseImages,
} from '@/components/pages/oberlo/utils/GetImageData';
import {useSiteData} from '@/hooks/useSiteData';
import type {
  ShopifyOberloData,
  OberloTopic,
} from '@/components/pages/oberlo/types';

import {
  BUSINESS_IMAGE_LIST_DATA,
  BUSINESS_IMAGE_LIST_DATA_2,
  DIVIDER_IMAGE_SIZES,
  businessImageContainerStyles,
  updatedBusinessImageContainerStyles,
} from '../constants/StartYourBusiness';
import {getShopifyArticleData} from '../utils/GetArticleData';

import Header from './Header';

type StartYourBusinessProps = {
  startYourBusinessData: ShopifyOberloData[];
  topics: OberloTopic[];
};

export default function StartYourBusiness({
  startYourBusinessData,
  topics,
}: StartYourBusinessProps) {
  const {t, images} = useTranslations();
  const {site} = useSiteData();
  const lang = site?.locale !== 'en' ? `/${site?.pathPrefix}` : '';
  // Render images for this page. This object returns two properties topImages and bottomImages
  // It allows to get all necessary data for images
  const currentImages = images.startYourBusiness.image.en;
  const businessImageListData = useMemo(
    () =>
      getBusinessImages(
        currentImages.businessPicturesList,
        BUSINESS_IMAGE_LIST_DATA,
      ),
    [currentImages],
  );

  const updatedBusinessImageListData = useMemo(
    () =>
      getBusinessImages(
        currentImages.updatedBusinessPicturesList,
        BUSINESS_IMAGE_LIST_DATA_2,
      ),
    [currentImages],
  );

  const dividerImageData = useMemo(
    () => ({
      src: currentImages.divider.src,
      ...DIVIDER_IMAGE_SIZES,
      alt: currentImages.divider.alt,
      loading: 'lazy' as Loading,
      sources: getSourseImages({
        srcSet: currentImages.divider.srcSet,
        srcSetWebp: currentImages.divider.srcSetWebp,
      }),
    }),
    [currentImages],
  );

  const articlesData = startYourBusinessData?.map((article) =>
    getShopifyArticleData({
      articleData: article,
      lang,
      topics,
      isImageAvailable: false,
    }),
  );

  return (
    <Section className="bg-sky-30 pt-12 pb-24 md:pt-24 md:pb-32">
      <div className="px-8 grid grid-cols-1 gap-12 justify-items-center items-center sm:px-16 sm:grid-cols-2 md:container md:w-full md:mx-auto md:grid-cols-3 md:gap-8 lg:gap-16">
        <Header topics={topics} />
        <div className="grid grid-cols-two-auto-width gap-4 relative pt-10">
          <div className="space-y-4 mt-28 relative">
            {businessImageListData?.map((businessImage, index) => (
              <Image
                key={businessImage.src}
                {...businessImage}
                pictureStyles={businessImageContainerStyles[index]}
              />
            ))}
          </div>
          <div className="space-y-4">
            {updatedBusinessImageListData?.map(
              (updatedBusinessImage, index) => (
                <Image
                  key={updatedBusinessImage.src}
                  pictureStyles={updatedBusinessImageContainerStyles[index]}
                  {...updatedBusinessImage}
                />
              ),
            )}
          </div>
        </div>
        <div className="space-y-8">
          {articlesData?.map((article, index) => (
            <Fragment key={article.title}>
              <Article
                {...(article as ArticleProps)}
                className="space-y-4"
                blogTitle={t('contentTypes.article')}
              />
              {index !== articlesData.length - 1 && (
                <Image
                  {...dividerImageData}
                  pictureStyles="bg-cover w-20 mt-8"
                />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </Section>
  );
}
