import OberloTypography from '@/components/pages/oberlo/components/OberloTypography/Typography';
import {useTranslations} from '@/hooks/useTranslations';
import Button from '@/components/pages/oberlo/components/LinkButton/LinkButton';
import {useSiteData} from '@/hooks/useSiteData';
import {ROOT_PATH} from '@/components/pages/oberlo/constants/index';
import type {OberloTopic} from '@/components/pages/oberlo/types';

interface HeaderProps {
  topics: OberloTopic[];
}

export default function Header({topics}: HeaderProps) {
  const {t} = useTranslations();
  const {site} = useSiteData();
  const lang = site?.locale !== 'en' ? `/${site?.pathPrefix}` : '';
  const dropshipping = topics?.filter((topic: OberloTopic) =>
    topic.handle.includes('dropshipping'),
  )[0];

  const dropshippingLink = `${dropshipping?.parentHandle}/${dropshipping?.handle}`;

  return (
    <div className="sm:col-span-2 md:col-span-1">
      <OberloTypography
        as="h2"
        className="text-label font-sans uppercase lg:text-size-xs"
      >
        {t('pages.home.startYourBusiness.label')}
      </OberloTypography>
      <OberloTypography
        as="h3"
        className="text-display-lg font-display uppercase lg:text-size-display-lg text-blue-10 mt-2"
      >
        {t('pages.home.startYourBusiness.title')}
      </OberloTypography>

      <OberloTypography className="mt-6">
        {t('pages.home.startYourBusiness.intro')}
      </OberloTypography>

      <Button
        className="mt-12 sm:inline-block"
        link={`${lang}${ROOT_PATH}/${dropshippingLink}`}
      >
        {t('pages.home.startYourBusiness.CTA')}
      </Button>
    </div>
  );
}
