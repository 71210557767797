import {useTranslations} from '@/hooks/useTranslations';
import GlobalArticle from '@/components/pages/oberlo/components/Article/GlobalArticle';
import type {ArticleProps} from '@/components/pages/oberlo/components/Article/Article';
import Article from '@/components/pages/oberlo/components/Article/Article';
import Section from '@/components/pages/oberlo/components/Section/Section';
import {useSiteData} from '@/hooks/useSiteData';
import type {
  OberloTopic,
  ShopifyOberloData,
} from '@/components/pages/oberlo/types';

import {getShopifyArticleData} from '../utils/GetArticleData';

type YourLifeProps = {
  yourLifeData: ShopifyOberloData[];
  topics: OberloTopic[];
};

export default function YourLife({yourLifeData, topics}: YourLifeProps) {
  const {t} = useTranslations();
  const {site} = useSiteData();
  const [firstArticle, ...otherArticles] = yourLifeData;
  const lang = site?.locale !== 'en' ? `/${site?.pathPrefix}` : '';

  const otherArticlesData = otherArticles?.map((article) =>
    getShopifyArticleData({
      articleData: article,
      lang,
      topics,
      isImageAvailable: false,
    }),
  );

  const globalArticleData = getShopifyArticleData({
    articleData: firstArticle,
    lang,
    topics,
  });

  return (
    <Section className="pt-12 pb-24 sm:pt-24 md:container md:w-full md:mx-auto">
      <GlobalArticle
        {...globalArticleData}
        t={t}
        header={t('pages.home.yourLife.title')}
        blogTitle={t('contentTypes.article')}
      />
      <div className="grid divide-y divide-gray-50 px-8 sm:px-16 md:gap-12 md:grid-cols-2 md:col-span-2 md:px-0 md:divide-y-0 xl:grid-cols-3">
        {otherArticlesData.map((article) => (
          <div key={article.title} className="py-8 md:py-0">
            <Article
              {...(article as ArticleProps)}
              className="space-y-4"
              blogTitle={t('contentTypes.article')}
            />
          </div>
        ))}
      </div>
    </Section>
  );
}
