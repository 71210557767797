import OberloTypography from '@/components/pages/oberlo/components/OberloTypography/Typography';
import {useTranslations} from '@/hooks/useTranslations';

export default function StoriesHeader() {
  const {t} = useTranslations();

  return (
    <div className="col-span-4 grid grid-cols-4 gap-12 px-8 py-12 content-start bg-black sm:col-span-8 sm:grid-cols-8 sm:gap-4 sm:px-16 md:px-8 xl:col-span-4 xl:grid-cols-4 xl:gap-12 xl:px-12">
      <OberloTypography
        as="h2"
        className="text-display-lg font-display uppercase lg:text-size-display-lg text-sky-30 col-span-4 sm:col-span-3 xl:col-span-4"
      >
        {t('pages.home.stories.title')}
      </OberloTypography>
      <OberloTypography className="text-body-lg font-sans lg:leading-7 text-gray-10 col-span-4 sm:col-span-5 xl:col-span-4">
        {t('pages.home.stories.intro')}
      </OberloTypography>
    </div>
  );
}
