import {useMemo} from 'react';

import {useTranslations} from '@/hooks/useTranslations';
import Section from '@/components/pages/oberlo/components/Section/Section';
import Button from '@/components/pages/oberlo/components/LinkButton/LinkButton';
import Image from '@/components/pages/oberlo/components/Image/Image';
import OberloTypography from '@/components/pages/oberlo/components/OberloTypography/Typography';
import {getImageData} from '@/components/pages/oberlo/utils/GetImageData';

import {IMAGE_SIZES} from '../constants/Hero';

type OberloHeroProps = {
  referringPageUrl: string;
};

export default function OberloHero({referringPageUrl}: OberloHeroProps) {
  const {t, images} = useTranslations();

  // Render images for hero page. This object returns two properties topImages and bottomImages
  // It allows to get all necessary data for images
  const heroImagesData = useMemo(
    () => getImageData(images.hero.image.en, IMAGE_SIZES),
    [images],
  );

  return (
    <Section className="bg-gray-10 pt-12 pb-24 relative px-8 md:px-[auto]">
      <div className="text-center container w-full mx-auto grid grid-cols-4 md:grid-cols-12">
        <div className="col-span-4 md:col-span-8 md:col-start-3">
          <OberloTypography
            as="h1"
            className="text-display-lg font-display uppercase sm:text-size-display-lg md:text-size-display-xl lg:text-size-display-2xl text-blue-10"
          >
            {t('pages.home.hero.title')}
          </OberloTypography>
          <OberloTypography className="mt-12 text-body-lg font-sans lg:leading-7">
            {t('pages.home.hero.text')}
          </OberloTypography>
          <Button
            type="secondary"
            className="mt-6 min-w-full md:min-w-0 !block md:!inline-block !text-[1rem] md:!text-[1.125rem] !whitespace-normal"
            link={t('pages.home.hero.link', {referringPageUrl})}
          >
            {t('pages.home.hero.join')}
          </Button>
        </div>
      </div>
      <div
        className="absolute top-0 mt-21 hidden lg:flex flex-col items-center w-full pointer-events-none"
        aria-hidden="true"
      >
        <div className="max-w-full w-400 flex justify-between">
          {heroImagesData.topImages.map((image) => (
            <Image
              key={image.src}
              {...image}
              loading="lazy"
              pictureStyles="bg-cover w-40 xl:w-55"
            />
          ))}
        </div>
        <div className="max-w-full w-360 flex justify-between mt-2">
          {heroImagesData.bottomImages.map((image) => (
            <Image
              key={image.src}
              {...image}
              loading="lazy"
              pictureStyles="bg-cover w-40 xl:w-55"
            />
          ))}
        </div>
      </div>
    </Section>
  );
}
