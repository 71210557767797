import {useTranslations} from '@/hooks/useTranslations';
import type {ImageProps} from '@/components/pages/oberlo/components/Image/Image';
import Article from '@/components/pages/oberlo/components/Article/Article';

import {IMAGE_TAB_INDEX, ARTICLE_IMAGE_SIZES} from '../constants/OurPicks';

export type ArticleItemProps = {
  description: string;
  blogLink: string;
  articleLink: string;
  articleTitle: string;
  title: string;
  titleLink: string;
  author: string;
  imageProps?: ImageProps;
  index: number;
};

export default function ArticleItem({
  description,
  blogLink,
  articleLink,
  articleTitle,
  title,
  titleLink,
  author,
  index,
  imageProps,
}: ArticleItemProps) {
  const {t} = useTranslations();

  return (
    <li
      className="min-w-full js-slide px-8 sm:px-16 md:px-0"
      id={`our-picks-${index}`}
      data-index={index}
    >
      <Article
        className="space-y-4"
        blogLink={blogLink}
        blogTitle={t('contentTypes.article')}
        articleLink={articleLink}
        articleTitle={articleTitle}
        title={title}
        titleLink={titleLink}
        description={description}
        author={author}
        imageProps={
          imageProps
            ? {
                ...imageProps,
                ...ARTICLE_IMAGE_SIZES,
                tabIndex: IMAGE_TAB_INDEX,
                loading: 'lazy',
                pictureStyles: 'bg-cover overflow-hidden relative pb-9/16',
                className:
                  'object-cover transform hover:scale-110 transition-transform ease-in duration-200 absolute w-full h-full object-cover',
              }
            : undefined
        }
      />
    </li>
  );
}
