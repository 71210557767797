import OrganizationSchema from '@/components/pages/oberlo/components/WebPageSchema/OrganizationSchema';
import {useTranslations} from '@/components/shared/Page/I18N';
import {useSiteData} from '@/components/shared/Page/SiteData';

export default function WebPageSchema() {
  const {t} = useTranslations();
  const {site} = useSiteData();
  const locale = `${site.languageCode}-${site.countryCode}`;

  return (
    <div
      itemProp="WebPage"
      itemID="https://www.oberlo.com/#webpage"
      itemScope
      hidden
      itemType="https://schema.org/WebPage"
    >
      <meta itemProp="name" content="Oberlo" />
      <meta itemProp="url" content="https://www.oberlo.com" />
      <meta itemProp="dateCreated" content="2016-04-21T13:20:00+02:00" />
      <meta itemProp="dateModified" content="2022-05-03T13:36:29+02:00" />
      <meta itemProp="datePublished" content="2016-04-21T13:20:00+02:00" />
      <meta itemProp="lastReviewed" content="2022-05-03T13:36:29+02:00" />
      <meta itemProp="description" content={t('htmlHead.metaDescription')} />
      <meta itemProp="inLanguage" content={locale} />
      <div
        itemProp="isPartOf"
        itemID="https://www.oberlo.com/#website"
        itemScope
        itemType="https://schema.org/WebSite"
      >
        <meta itemProp="url" content="https://www.oberlo.com/" />
        <meta itemProp="name" content="Oberlo" />
        <meta itemProp="description" content={t('htmlHead.metaDescription')} />
        <meta itemProp="inLanguage" content={locale} />
        <div
          itemProp="publisher"
          itemID="https://www.oberlo.com/#organization"
          itemScope
          itemType="https://schema.org/Organization"
        >
          <OrganizationSchema locale={locale} />
        </div>
      </div>
      <div
        itemProp="about"
        itemID="https://www.oberlo.com/#about"
        itemScope
        itemType="https://schema.org/Organization"
      >
        <meta itemProp="name" content="https://www.oberlo.com/#organization" />
      </div>
      <div
        itemProp="primaryImageOfPage"
        itemID="https://www.oberlo.com/#primaryimage"
        itemScope
        itemType="https://schema.org/ImageObject"
      >
        <meta
          itemProp="url"
          content="https://cdn.shopify.com/s/files/1/0840/8370/3830/files/1593088941-oberlo-logo.png"
        />
        <meta itemProp="inLanguage" content={locale} />
      </div>
      <div itemProp="reviewedBy" itemScope itemType="https://schema.org/Thing">
        <meta itemProp="name" content="https://www.oberlo.com/#organization" />
      </div>
      <div
        itemProp="potentialAction"
        itemScope
        itemType="https://schema.org/ReadAction"
      >
        <div
          itemProp="target"
          itemScope
          itemType="https://schema.org/EntryPoint"
        >
          <meta itemProp="urlTemplate" content="https://www.oberlo.com" />
        </div>
      </div>
    </div>
  );
}
