import {ImageResponseType} from '../types';

const ARROW_IMAGE_WIDTH = 334;
const ARROW_IMAGE_HEIGHT = 164;

export const TAG_KEY = 'key:';

export const storiesData = [
  {
    articleClass: 'min-h-75 relative bg-black sm:col-span-8',
    height: 960,
    width: 1440,
    imageType: ImageResponseType.ImageMd,
  },
  {
    articleClass: 'row-span-2 min-h-75 relative bg-black',
    height: 619,
    width: 450,
    imageType: ImageResponseType.ImageMd,
  },
  {
    articleClass: 'row-span-2 min-h-75 relative bg-black',
    height: 619,
    width: 450,
    imageType: ImageResponseType.ImageMd,
  },
  {
    articleClass: 'min-h-75 relative bg-black sm:min-h-64',
    height: 378,
    width: 672,
    imageType: ImageResponseType.ImageMd,
  },
];

export const ARROW_IMAGE_SIZES = {
  height: ARROW_IMAGE_WIDTH,
  width: ARROW_IMAGE_HEIGHT,
};
