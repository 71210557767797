export default function OrganizationSchema({locale}: {locale: string}) {
  return (
    <>
      <meta itemProp="name" content="Oberlo" />
      <meta itemProp="url" content="https://www.oberlo.com/" />
      <meta itemProp="email" content="mailto:hello@oberlo.com" />
      <meta itemProp="foundingDate" content="2015-10-01" />
      <meta itemProp="sameAs" content="https://www.facebook.com/oberloapp" />
      <meta itemProp="sameAs" content="https://www.tiktok.com/@oberlo" />
      <meta itemProp="sameAs" content="https://twitter.com/OberloApp" />
      <meta itemProp="sameAs" content="https://www.instagram.com/oberloapp/" />
      <meta itemProp="sameAs" content="https://www.youtube.com/c/oberlo" />
      <meta
        itemProp="sameAs"
        content="https://www.linkedin.com/company/oberlo/"
      />
      <meta itemProp="sameAs" content="https://www.pinterest.com/oberloapp/" />

      <div
        itemProp="address"
        itemScope
        itemType="https://schema.org/PostalAddress"
      >
        <meta itemProp="addressLocality" content="Vilnius" />
        <meta itemProp="addressRegion" content="Lithuania" />
        <meta itemProp="postalCode" content="08128" />
        <meta
          itemProp="streetAddress"
          content="Švitrigailos g. 11A, LT-03228 Vilnius, Lithuania"
        />
      </div>
      <div
        itemProp="foundingLocation"
        itemScope
        itemType="https://schema.org/Place"
      >
        <meta itemProp="name" content="Lithuania" />
      </div>
      <div itemProp="founders" itemScope itemType="https://schema.org/Person">
        <meta itemProp="name" content="Tomas Slimas" />
      </div>
      <div itemProp="founders" itemScope itemType="https://schema.org/Person">
        <meta itemProp="name" content="Andrius Slimas" />
      </div>
      <div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
        <meta itemProp="caption" content="Oberlo Logo" />
        <meta
          itemProp="url"
          content="https://cdn.shopify.com/s/files/1/0840/8370/3830/files/1593088941-oberlo-logo.png"
        />
        <meta itemProp="inLanguage" content={locale} />
      </div>
      <div itemProp="image" itemScope itemType="https://schema.org/ImageObject">
        <meta itemProp="caption" content="Oberlo Logo" />
        <meta
          itemProp="url"
          content="https://cdn.shopify.com/s/files/1/0840/8370/3830/files/1593088941-oberlo-logo.png"
        />
        <meta itemProp="inLanguage" content={locale} />
      </div>
    </>
  );
}
