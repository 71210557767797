import Section from '@/components/pages/oberlo/components/Section/Section';
import {useTranslations} from '@/hooks/useTranslations';
import GlobalArticle from '@/components/pages/oberlo/components/Article/GlobalArticle';
import {useSiteData} from '@/hooks/useSiteData';
import type {
  OberloTopic,
  ShopifyOberloData,
} from '@/components/pages/oberlo/types';

import {getShopifyArticleData} from '../utils/GetArticleData';

import type {ArticleItemProps} from './ArticleItem';
import ArticleItem from './ArticleItem';

type OurPicksProps = {
  ourPicksData: ShopifyOberloData[];
  topics: OberloTopic[];
};

export default function OurPicks({ourPicksData, topics}: OurPicksProps) {
  const {site} = useSiteData();
  const {t} = useTranslations();
  const [firstArticle, ...otherArticles] = ourPicksData;
  const lang = site?.locale !== 'en' ? `/${site?.pathPrefix}` : '';

  const otherArticlesData = otherArticles?.map((article) =>
    getShopifyArticleData({articleData: article, lang, topics}),
  );

  const globalArticleData = getShopifyArticleData({
    articleData: firstArticle,
    lang,
    topics,
  });

  return (
    <Section className="md:container md:w-full md:mx-auto pb-24">
      <GlobalArticle
        {...globalArticleData}
        t={t}
        className="bg-sky-30"
        header={t('pages.home.ourPicks.title')}
        blogTitle={t('contentTypes.article')}
      />
      <ul className="flex flex-nowrap pb-6 pt-12 overflow-x-auto md:grid md:gap-12 md:grid-cols-2 md:py-0 lg:grid-cols-3">
        {otherArticlesData.map((article, index) => (
          <ArticleItem
            key={article.title}
            {...(article as Omit<ArticleItemProps, 'index'>)}
            index={index}
          />
        ))}
      </ul>
    </Section>
  );
}
