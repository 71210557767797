import {useMemo} from 'react';

import {useTranslations} from '@/hooks/useTranslations';
import Button from '@/components/pages/oberlo/components/LinkButton/LinkButton';
import Section from '@/components/pages/oberlo/components/Section/Section';
import OberloTypography from '@/components/pages/oberlo/components/OberloTypography/Typography';
import Image from '@/components/pages/oberlo/components/Image/Image';
import ApplePodcastsIcon from '@/components/pages/oberlo/icons/ApplePodcastsIcon';
import SpotifyIcon from '@/components/pages/oberlo/icons/SpotifyIcon';
import {getSourseImages} from '@/components/pages/oberlo/utils/GetImageData';
import {ROOT_PATH} from '@/components/pages/oberlo/constants';
import type {ShopifyOberloData} from '@/components/pages/oberlo/types';

import {IMAGE_SIZES} from '../constants/Podcast';
import {getMaxString} from '../utils/GetMaxString';

const PODCAST_LINK = '/podcast';

type PodcastProps = {
  podcast: ShopifyOberloData;
};

export default function Podcast({podcast}: PodcastProps) {
  const {t, images} = useTranslations();

  const podcastImage = images.podcast.image.en;

  const podcastImageData = useMemo(
    () => ({
      src: podcastImage.src,
      ...IMAGE_SIZES,
      alt: podcastImage.alt,
      sources: getSourseImages({
        srcSet: podcastImage.srcSet,
        srcSetWebp: podcastImage.srcSetWebp,
      }),
    }),
    [podcastImage],
  );

  const podcastLink = `${ROOT_PATH}${PODCAST_LINK}/${podcast?.handle}`;

  return (
    <Section className="bg-gray-10 py-12 px-8 sm:px-16 md:px-0">
      <div className="mx-auto grid grid-cols-4 gap-12 sm:grid-cols-8 md:grid-cols-12 md:container md:w-full md:mx-auto ">
        <div className="col-span-4 flex justify-center flex-col">
          <OberloTypography
            as="h2"
            className="block text-label font-sans uppercase lg:text-size-xs"
          >
            {t('pages.home.podcast.label')}
          </OberloTypography>
          <OberloTypography
            as="h3"
            className="block text-display-lg font-display uppercase lg:text-size-display-lg text-blue-10 my-4"
          >
            {t('pages.home.podcast.title')}
          </OberloTypography>
          <OberloTypography>{t('pages.home.podcast.intro')}</OberloTypography>
          <div className="mt-12">
            <a
              href={t('pages.home.podcast.linkApplePodcast')}
              className="inline-block mr-6"
            >
              <OberloTypography as="span" className="sr-only">
                {t('pages.home.podcast.listenOnApplePodcast')}
              </OberloTypography>
              <ApplePodcastsIcon />
            </a>
            <a
              href={t('pages.home.podcast.linkSpotifyPodcast')}
              className="inline-block"
            >
              <OberloTypography as="span" className="sr-only">
                {t('pages.home.podcast.listenOnSpotify')}
              </OberloTypography>
              <SpotifyIcon />
            </a>
          </div>
        </div>

        <div className="col-span-4 flex justify-center" aria-hidden="true">
          <Image
            {...podcastImageData}
            loading="lazy"
            pictureStyles="bg-cover max-w-full max-w-xs block md:p-4 md:-my-4 md:max-w-xs"
          />
        </div>

        <div className="col-span-4 flex justify-center flex-col sm:col-span-8 md:col-span-4">
          <OberloTypography
            as="h2"
            className="block text-label font-sans uppercase lg:text-size-xs text-blue-10 mb-2"
          >
            {t('pages.home.podcast.latestEpisodeLabel')}
          </OberloTypography>
          <Button
            type="link"
            className="block mb-4 hover:underline"
            link={`${podcastLink}`}
          >
            <OberloTypography as="h3" className="text-heading-base font-sans">
              {podcast?.title}
            </OberloTypography>
          </Button>
          <OberloTypography>
            {podcast?.excerpt ? getMaxString(podcast?.excerpt) : ''}
          </OberloTypography>
          <Button
            className="sm:inline-flex sm:self-start mt-12"
            link={`${podcastLink}`}
          >
            {t('pages.home.podcast.CTA')}
          </Button>
        </div>
      </div>
    </Section>
  );
}
